.external_ticket_form {
  height: 100%;
  display: flex;
  flex-direction: column;
  .ticket_input_wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 10px;
    .input_label {
      font: 0.8rem 'Rubik', sans-serif;
      cursor: pointer;
      margin: 0 calc(var(--standard-margin) / 2);
      font-weight: 500;
      &.fixed_width {
        width: 60px;
        text-align: right;
      }
    }
    .create_ticket_input {
      input {
        border: 0;
        border-bottom: 1px solid #ced4da;
        outline: 0;
        padding: calc(var(--standard-padding) / 2);
        font-size: 0.8rem;
      }
    }
  }
  .create_ticket_submit {
    width: fit-content;
    align-self: flex-end;
  }
}