.actions_button_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--standard-margin);
  // box-shadow: var(--card-shadow);
  // box-shadow: 0 0 0 0.15rem rgba(230, 230, 230, 0.5);
  background-color: var(--background-1);
  color: var(--color-1);
  padding: var(--standard-padding);
  border-top: 1px solid var(--border-color-1);
  // margin-bottom: 0.2rem;
  // border-radius: 10px;
  .left_section {
    display: flex;
    align-items: center;
    flex: auto;
    button {
      margin-right: var(--standard-margin);
    }
    .block-ui {
      min-height: unset;
      line-height: 0;
    }
    .cancel_button {
      margin-right: var(--standard-margin);
      cursor: pointer;
    }
    .red_alert_typing {
      color: var(--font-color-600);
      font: 0.9rem 'Rubik', sans-serif;
    }
  }
  .right_section {
    display: flex;
    align-items: center;
    .trigger {
      display: flex;
      align-items: center;
      .footer_action_icons {
        margin-right: calc(var(--standard-margin) * 2);
        color: var(--color-1);
        cursor: pointer;
      }
    }
  }
}

.mobile {
  .actions_button_section {
    margin-top: 0.2rem;
    .left_section {
      .primary_btn {
        padding: calc(var(--standard-padding) / 2);
        margin-left: calc(var(--standard-margin) / 2);
        font: 0.7rem 'Rubik', sans-serif;
        min-height: 25px;
      }
    }
    .right_section {
      .primary_btn {
        padding: calc(var(--standard-padding) / 2);
        font: 0.7rem 'Rubik', sans-serif;
        min-height: 25px;
      }
      .predefined_snippet_list {
        .dropdown_list {
          max-width: 250px;
        }
      }
    }
  }
}