.todo_dialog {
  width: 350px;
  .todo_form {
    width: 100%;
    .todo_input_wrapper {
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      span {
        font-weight: 400;
      }
    }
  }
}