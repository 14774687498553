.text_editor_section {
  height: 100%;
  width: 100%;
  background-color: var(--text-area-bg-color);
  box-shadow: var(--card-shadow);
  outline: unset;
}

.forward_input_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--standard-margin);
  font: 1rem 'Rubik', sans-serif;
  &.forwardError {
    margin-bottom: unset;
    input {
      border: 1px solid #ff0000;
    }
  }
  .forward_input {
    display: flex;
    flex-direction: column;
    margin-left:  var(--standard-margin);
    input {
      width: 250px;
      padding: 0;
    }
    p {
      font: 0.8rem 'Rubik', sans-serif;
      color: #ff0000;
    }
  }
}

.mobile {
  .text_editor_section {
    box-shadow: unset;
    font: 0.8rem 'Rubik', sans-serif;
    font-weight: 300;
  }
  .forward_email_input {
    margin: 0 calc(var(--standard-margin)/2) calc(var(--standard-margin)/2);
    input {
      flex: 1;
    }
  }
}