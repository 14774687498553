.pluse_animate {
  animation: pulse 2s infinite;
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(248, 182, 81, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 5px rgba(248, 182, 81, 0.6);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(248, 182, 81, 0.4);
      box-shadow: 0 0 0 0 rgba(248, 182, 81, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 5px rgba(248, 182, 81, 0.6);
        box-shadow: 0 0 0 5px rgba(248, 182, 81, 0.6);
    }
  }
}

.blink_animate {
  animation: blink-animation 3s infinite;
  -webkit-animation: blink-animation 3s infinite;
}
@keyframes blink-animation {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes blink-animation {
  to {
    opacity: 0;
  }
}