.mobile_navigation_section {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: var(--dialog-outer-background-1);
  transition: .5s;
  z-index: 501;
  display: flex;
  .mobile_navigation_container {
    height: 100%;
    flex: 0.6;
    background-color: var(--background-1);
    box-shadow: 2px 10px 30px 0 rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    .navigation_header {
      display: flex;
      align-items: center;
      padding: var(--standard-padding);
      justify-content: space-between;
      height: 55px;
      img {
        height: 100%;
      }
      svg {
        font: 1.5rem 'Rubik', sans-serif;
        color: var(--color-1);
      }
    }
    .navigation_item_list {
      list-style: none ;
      padding-left: 0;
      overflow: auto;
      margin-top: 0;
      li {
        text-decoration: none;
        margin-bottom: 4px;
        color: var(--color-1);
        &.activeNav {
          background-color: var(--theme-background-200);
          .navigation_item {
            color: var(--font-color-200);
          }
        }
        .navigation_item {
          display: flex;
          padding: calc(var(--standard-padding) * 2);
          align-items: center;
          color: var(--color-1);
          text-decoration: none;
          svg {
            font: 1.4rem 'Rubik', sans-serif;
            margin-right: calc(var(--standard-padding) * 2);
          }
        }
      }
    }
  }
  .navigation_overlay_bar {
    flex: 0.4;
    background-color: rgba(0, 0, 0, 0);
  }
}
