.primary_btn {
  min-width: 65px;
  border: 0;
  box-shadow: var(--card-shadow);
  padding: var(--standard-padding) calc(var(--standard-padding) * 2);
  font: 0.8rem 'Rubik', sans-serif;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--font-color-100);
  background-color: var(--background-2);
  outline: none;
  &:hover {
    background: var(--background-2) radial-gradient(circle, transparent 1%, var(--background-2) 1%) center/14000%;
  }
  &:active {
    background-color: inherit;
    background-size: 100%;
    transition: background 0s!important;
  }
  &.ripple {
    background-position: center;
    transition: background 0.8s;
  }
}