.dialog_section {
  border-top: 5px solid var(--border-color-1);
  background-color: var(--background-1);
  border-radius: 10px;
  width: 500px;
  display: flex;
  flex-direction: column;
  color: var(--color-1);
  .card_close {
    cursor: pointer;
    color: var(--background-3);
    font: 1.5rem 'Rubik', sans-serif;
    outline: none;
  }
  .card_title {
    font: 1.25rem 'Rubik', sans-serif;
    font-family: var(--font-family);
    font-weight: 400;
    line-height: 1;
    color: var(--color-1);
    display: flex;
    margin-bottom: 1rem;
  }
}