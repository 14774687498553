.working_hours_dialog {
  .working_hours_table {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border: 1px solid var(--separator-color);
    :last-child {
      border-bottom: unset;
    }
    div {
      border-bottom: 1px solid var(--separator-color);
    }
    .table_header {
      display: flex;
      flex: 1;
      font-weight: bold;
      :not(:last-child) {
        border-right: 1px solid var(--separator-color);
      }
      p {
        flex: 1;
        text-align: center;
      }
    }
    .table_item {
      display: flex;
      flex: 1;
      :not(:last-child) {
        border-right: 1px solid var(--separator-color);
      }
      p {
        flex: 1;
        text-align: center;
      }
    }
  }
  .invoice_submit {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--standard-margin);
  }
}
