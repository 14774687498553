.ticket_list_section {
  height: 100%;
  max-width: 100%;
  .react_table {
    position: relative;
    display: flex;
    flex-direction: column;
    // border: 1px solid rgba(0,0,0,.1);
    height: 100%;
    .ticket_table_date_changed {
      font: 0.9rem 'Rubik', sans-serif;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 1400px) and (max-width: 1550px) {
        font: 0.83rem 'Rubik', sans-serif;
      }
      @media screen and (min-width: 1300px) and (max-width: 1399px) {
        font: 0.73rem 'Rubik', sans-serif;
      }
      @media screen and (min-width: 1200px) and (max-width: 1299px) {
        font: 0.65rem 'Rubik', sans-serif;
      }
      @media screen and (min-width: 1100px) and (max-width: 1199px) {
        font: 0.62rem 'Rubik', sans-serif;
      }
      @media screen and (min-width: 980px) and (max-width: 1099px) {
        font: 0.6rem 'Rubik', sans-serif;
      }
    }
    .clipboard_copy_btn {
      border: 0;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      font: 0.8rem 'Rubik', sans-serif;
      color: var(--color-1);
    }
    .tooltip_text {
      position: absolute;
      top: 5px;
      right: 17px;
      padding: 0 0.2rem;
      font: 0.8rem 'Rubik', sans-serif;
      border-radius: 5px;
      color: #FFF;
      background-color: #333;
      z-index: 1000;
    }
    .flag_actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: 1rem;
      >:first-child {
        margin-right: 3px;
      }
      svg {
        cursor: pointer;
        font-size: 0.9rem;
      }
    }
    .status_info {
      font: 0.9rem 'Rubik', sans-serif;
    }
  }
}

.profile_margin_left {
  margin-left: calc(var(--standard-margin) * 2);
}