.custom_ticket_filter {
  border-bottom: 1px solid var(--border-color-1);
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--background-1);
  .filters_info {
    display: flex;
    align-items: center;
    .title {
      font: 0.8rem 'Rubik', sans-serif;
      font-weight: 700;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: var(--color-1);
      .flag_icon {
        font: 1rem 'Rubik', sans-serif;
      }
      svg {
        font: 1.2rem 'Rubik', sans-serif;
        border-radius: 50px;
        color: var(--color-1);
        width: 35px;
        &.low_opacity {
          opacity: 0.3;
        }
      }
    }
  }
  .reset_btn {
    font: 1.2rem 'Rubik', sans-serif;
    border-radius: 50px;
    color: var(--color-1);
    cursor: pointer;
    width: 35px;
  }
}