.ticket_message_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
  .ticket_messages_container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    .chat_content_wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      overflow: auto;
      position: relative;
      .ticket_list {
        height: 100%;
        flex: 1 1;
        overflow-y: scroll;
        position: relative;
        // margin: var(--standard-margin) 0;
        padding: 0 var(--standard-padding);
        background-color: var(--theme-background-1);
        .message_box_section {
          width: calc(100% - var(--standard-margin));
          margin: calc(var(--standard-margin) / 2);
          box-shadow: var(--card-shadow);
          overflow: hidden;
        }
      }
    }
    .text_editor {
      width: 100%;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
    }
  }
}

.mobile {
  .ticket_message_section {
    flex: 1;
    .ticket_messages_container {
      .chat_content_wrapper {
        .ticket_list {
          padding: 0 calc(var(--standard-padding) * 2) 0 4px;
        }
      }
      .text_editor {
        flex: unset;
      }
    }
  }
}