.note_section {
  width: 100%;
  background-color: var(--note-background-100);
  color: var(--note-color-1);
  padding: var(--standard-padding);
  line-height: 24px;
  min-height: 35px;
  &.shadow_effect {
    position: relative;
    &:before, &:after{
      content: '';
      z-index: -1;
      position: absolute;
      bottom: 1em;
      width: 20%;
      top: 75%;  
      background: var(--note-box-shadow);
      box-shadow: 0 1em .5em var(--note-box-shadow);   
    }
    &:before{
      transform: rotate(-3deg); 
      left: .5em;     
    }
    &:after{
      transform: rotate(3deg);    
      right: .5em;    
    }
  }
}