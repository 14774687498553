.tab_rendered_seciton {
  display: flex;
  white-space: nowrap;
  overflow-y: auto;
  justify-content: flex-start;
  a {
    text-decoration: none;
  }
  .home_tab {
    width: auto !important;
    display: flex;
    align-items: center;
    margin-right: var(--standard-margin);
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 120px;
    height: 30px;
    padding: 0.3rem;
    font: 0.7rem 'Rubik', sans-serif;
    font-weight: 500;
    line-height: 1;
    background-color: var(--tab-background-1);
    color: var(--tab-color-1);
    border: 2px solid transparent;
    cursor: pointer;
    position: relative;
    &.active {
      background-color: var(--tab-active-color);
      border-color: var(--border-color-1) !important;
      border-bottom: none;
    }
    .create_ticket_dropdown {
      .dropdown_btn {
        svg {
          font: 1rem 'Rubik', sans-serif;
          color: var(--tab-color-1);
        }
      }
    }
    .items_length {
      margin-left: calc(var(--standard-margin) / 2);
      font: inherit 'Rubik', sans-serif;
      &:after{
        content: ')'
      }
      &:before{
        content: '('
      }
    }
    mwc-linear-progress {
      --mdc-theme-primary: var(--theme-background-300);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .tab_item {
    margin-right: var(--standard-margin);
    position: relative;
    .badge_item {
      border-bottom: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 120px;
      height: 30px;
      font-weight: 300;
    }
  }
}

.mobile {
  .tab_rendered_seciton {
    margin: 0 calc(var(--standard-margin) / 2);
    .home_tab {
      margin-right: calc(var(--standard-margin) / 2);
    }
    .tab_item {
      margin-right: calc(var(--standard-margin) / 2);
    }
  }
}

#portal {
  .create_ticket_popover {
    margin-top: calc(var(--standard-margin) * 3);
  }
}