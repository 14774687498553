.flag_section {
  display: flex;
  .star {
    font-size: 1.2rem!important;
    cursor: pointer;
    &.yellow {
      color: #F6B450;
    }
    &.red {
      color: #ff0000;
    }
    &.green {
      color: #228B22;
    }
  }
}