.gruoup_chat_attachment {
  display: flex;
  margin-top: 0.3rem;
  min-width: 200px;
  .attachment_img {
    width: 50%;
    text-align: center;
    box-shadow: var(--card-shadow);
    padding: calc(var(--standard-padding) / 2);
    border-radius: var(--primary-radius);
    background-color: var(--theme-background-100);
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 100%;
      display: flex;
    }
  }
  .attachment_info {
    width: 50%;
    margin-left: var(--standard-margin);
    color: var(--color-1);
    .file_info {
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-bottom: 2px;
      svg {
        margin-right: 0.2rem;
        font: 1rem 'Rubik', sans-serif;
      }
      .filename {
        white-space: nowrap;
        font: 0.8rem 'Rubik', sans-serif;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .actions_info {
      display: flex;
      white-space: nowrap;
      >:first-child {
        margin-right: 0.5rem;
      }
      p {
        font: 0.7rem 'Rubik', sans-serif;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}