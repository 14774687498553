.chat_header_section {
  display: flex;
  width: 100%;
  color: var(--color-1);
  border: 1px solid var(--border-color-1);
  padding: var(--standard-padding);
  // border-radius: calc(var(--primary-radius) * 2);
  background-color: var(--background-1);
  flex-direction: column;
  position: relative;
  .chat_header_container {
    display: flex;
    .ext_link {
      display: flex;
      color: var(--color-1);
      svg {
        position: relative;
        top: 2px;
      }
    }

    .screen_share {
      margin-right: 10px;
      margin-left: 3px;
    }
    .chat_header_left {
      flex: 1;
      width: 50%;
      display: flex;
      flex-direction: column;
      .chat_title {
        font: 0.9rem "Rubik", sans-serif;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .chat_preview {
        font: 0.9rem "Rubik", sans-serif;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .chat_header_right {
      width: 50%;
      flex: auto;
      display: flex;
      justify-content: flex-end;
      .ticket_info {
        display: flex;
        flex-direction: column;
        .ticket_id {
          display: flex;
          position: relative;
          flex-wrap: nowrap;
          span {
            font: 0.9rem "Rubik", sans-serif;
            font-weight: 500;
            white-space: nowrap;
          }
          .clipboard_copy_btn {
            border: 0;
            background-color: transparent;
            outline: none;
            cursor: pointer;
            svg {
              color: var(--color-1);
              font: 1rem "Rubik", sans-serif;
            }
            .tooltip_text {
              position: absolute;
              bottom: -25px;
              right: 0px;
              padding: 0.2rem;
              border-radius: 5px;
              color: var(--font-color-100);
              background-color: #333;
              z-index: 1000;
            }
          }
        }
        .social_info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .email_domain {
            font: 0.8rem "Rubik", sans-serif;
            font-weight: 500;
            flex: 1;
          }
          .todo_icons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            > :first-child {
              margin: 0 0.25rem;
            }
          }
          svg {
            cursor: pointer;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  .header_search_section {
    display: flex;
    margin-top: 0.3rem;
    align-items: center;
    justify-content: flex-end;
    .header_search {
      width: 200px;
      border: 1px solid #ddd;
      height: 25px;
      outline: 0;
      margin-right: 0.5rem;
    }
    svg {
      cursor: pointer;
    }
  }
}

.mobile {
  .chat_header_section {
    padding: calc(var(--standard-padding) / 2) var(--standard-padding);
    .chat_header_container {
      flex-direction: column;
      .chat_header_left {
        width: 100%;
        .chat_title {
          line-height: 1;
        }
        .chat_preview {
          font: 0.7rem "Rubik", sans-serif;
        }
      }
      .chat_header_right {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        .participant_info {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.2rem;
        }
        .agent_badge {
          margin-left: 0;
        }
        .agent_badge {
          height: 15px;
          font: 0.7rem "Rubik", sans-serif;
        }
        .ticket_info {
          flex-direction: row;
          justify-content: space-between;
          flex: 1;
          .ticket_id {
            span {
              font: 0.8rem "Rubik", sans-serif;
            }
            .ext_link {
              font: 0.8rem "Rubik", sans-serif;
              .screen_share {
                margin-right: 5px;
              }
            }
            .clipboard_copy_btn {
              svg {
                font: 0.8rem "Rubik", sans-serif;
              }
            }
          }
        }
      }
    }
  }
}
