.main_template {
  height: 100%;
  width: 100%;
  .app_content_section {
    height: 100%;
    width: 100%;
    padding-top: 55px;
    .app_content_container {
      height: calc(100vh - 55px);
      width: 100%;
    }
  }
}
