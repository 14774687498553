.participant_info {
  float: auto;
  height: fit-content;
  font: 0.8rem 'Rubik', sans-serif;
  line-height: 13px;
  cursor: pointer;
  margin-right: var(--standard-margin);
  .participant_email {
    white-space: nowrap;
    display: flex;
    .recipient {
      font-weight: 500;
      margin-right: calc(var(--standard-margin) / 2);
    }
    .recipient_list {
      white-space: pre-line;
      display: flex;
      .more_link {
        align-self: flex-end;
        .dropdown_btn {
          .title {
            font-weight: bold;
            font: 0.7rem 'Rubik', sans-serif;
            margin-left: 0.1rem;
          }
        }
      }
    }
  }
}
.add_recipients_info {
  font: 0.9rem 'Rubik', sans-serif;
  margin-right: var(--standard-margin);
  cursor: pointer;
  height: fit-content;
  display: flex;
  align-items: center;
  svg {
    margin-right: calc(var(--standard-margin) / 2);
  }
}

#portal {
  .more_link_popover {
    padding: var(--standard-padding);
  }
}