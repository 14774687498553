.ticket_dropzone_section {
  display: flex;
  margin: var(--standard-margin) 0;
  height: 90px;
  .dropzone {
    cursor: pointer;
    flex: 1;
  }
  .dropdown_preview_list {
    padding: calc(var(--standard-padding) / 2);
    flex: 1;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid var(--separator-color);
    border-style: dashed;
    margin-left: var(--standard-margin);
    background-color: #fafafa;
    color: #bdbdbd;
    .preview_item {
      border-radius: 2px;
      width: 110px;
      min-height: 75px;
      margin-right: calc(var(--standard-margin) / 2);
      margin-bottom: calc(var(--standard-margin) / 2);
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .perview_image {
        border: 1px solid var(--separator-color);
        padding: calc(var(--standard-padding) / 2);
        width: 55px;
        height: 55px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .preview_close_item {
        position: absolute;
        left: 0px;
        top: 0px;
        color: var(--theme-background-300);
        cursor: pointer;
      }
      .file_info {
        font: 0.9rem 'Rubik', sans-serif;
        white-space: nowrap;
        display: flex;
        width: 100%;
        .filename {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .extension {
          &::before {
            content: '.';
          }
        }
      }
    }
  }
}