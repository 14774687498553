.top_navigation_section {
  height: 55px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 500;
  background-color: var(--background-1);
  box-shadow: var(--card-shadow);
  color: var(--color-1);
  .top_navigation_container {
    height: 100%;
    display: flex;
    align-items: center;
    padding: var(--standard-padding);
    .group_chat_icon {
        border-radius: 50%;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        margin: 10px;
        height: 30px;
        width: 30px;
        transform: scale(1);
        animation: pulse-black 2s infinite;
    }
    .group_chat_icon.pulse {
      // backgrounds: rgba(255, 121, 63, 1);
      box-shadow: 0 0 0 2px rgba(255, 121, 63, 1);
      animation: pulse-orange 1.5s infinite;
    }

    .glow-line{
      position: absolute;
      width: 100%;
      height:1px;
      top: -1px;
      left: 0px;
      animation: glow 1.5s infinite;
    }

    @keyframes pulse-orange {
        0% {
            transform: scale(0.65);
            box-shadow: 0 0 0 2px rgba(255, 121, 63, 0.7);
        }
        70% {
            transform: scale(4);
            box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
        }
        100% {
            transform: scale(0.65);
            box-shadow: 0 0 0 5px rgba(255, 121, 63, 0);
        }
    }

    @keyframes glow {
        0% {
            box-shadow: 0 0 30px 10px rgba(255, 121, 63, 0.7);
        }
        70% {
            box-shadow: 0 0 20px 20px rgba(255, 121, 63, 0.7);
        }
        100% {
            box-shadow: 0 0 30px 10px rgba(255, 121, 63, 0.7);
        }
    }
    .group_chat_icon {
      position: relative;
      cursor: pointer;
      svg {
        font: 1.3rem 'Rubik', sans-serif;
        margin-right: 0.3rem;
        position: relative;
        top: 5px;
        left: 5px;
      }
      .count_badge {
        font: 0.6rem 'Rubik', sans-serif;
        position: absolute;
        border-radius: 25px;
        color: var(--font-color-100);
        background-color: var(--theme-background-300);
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -7px;
        right: 2px;
      }
    }
    .main_logo {
      height: 100%;
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      .menu_icon {
        font: 1.2rem 'Rubik', sans-serif;
      }
      .top_navigation_left {
        display: flex;
        align-items: center;
        height: 100%;
        a {
          height: 100%;
          align-items: center;
          display: flex;
          .crushftp_logo {
            height: 100%!important;
            cursor: pointer;
          }
        }
      }
      .header_content {
        height: 100%;
        display: flex;
        align-items: center;
        .search_input {
          &:focus {
            outline: none;
          }
        }
        .search_input {
          svg {
            font: 1.3rem 'Rubik', sans-serif;
            margin: 0 var(--standard-margin);
            cursor: pointer;
            &:hover {
              color: var(--color-2);
            }
            &:focus {
              outline: none;
            }
          }
        }
        .search_info_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: var(--standard-margin);
        }
        .goto_meeting {
          height: 100%;
          display: flex;
          align-items: center;
          .teams_meeting_img {
            height: 80%!important;
            cursor: pointer;
          }
        }
        .dropdown_section {
          .dropdown_btn {
            color: var(--color-1);
            svg {
              font: 1.3rem 'Rubik', sans-serif;
              margin: 0 0.5rem;
              cursor: pointer;
              color: var(--color-1);
            }
          }
        }
      }
    }
    .state-warning{
      position: fixed;
      top:40px;
      padding: 10px;
      left: 50%;
      margin-left: -275px;
      width: 550px;
      text-align: center;
      background: #bb0326;
      color: #fff;
      border-radius: 10px;
      font-weight: bold;
      font-size: 15px;
    }
  }
}

.mobile {
  .top_navigation_section {
    .top_navigation_container {
      .mobile_search_section {
        display: flex;
        align-items: center;
        flex: 1;
        .search_input {
          width: 100%;
          input {
            width: 100%;
          }
        }
        svg {
          font: 1.2rem 'Rubik', sans-serif;
          margin: 0 var(--standard-margin);
        }
      }
    }
  }
}
