.profile_item {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  .profile_content {
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    .profile_pic {
      height: 100%;
      img {
        height: 100%;
      }
    }
    .roundedd {
      img {
        border-radius: 50%;
      }
    }
    .profile_info {
      // padding: 0 calc(var(--standard-padding) * 2);
      color: var(--color-1);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .profile_username {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
      }
      .subtitle {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .progress_content {
    font: 0.7rem 'Rubik', sans-serif;
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }
}