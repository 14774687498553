.group_chat_section {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  .group_chat_container {
    width: 100%;
    box-shadow: 0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1);
    height: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .group_chat_content {
      height: 100%;
      flex: 1;
      border-top: 2px solid var(--border-color-1);
      border-bottom: 2px solid var(--border-color-1);
      padding: 0 var(--standard-padding);
      overflow: auto;
      display: flex;
      flex-direction: column;
      padding-top: 0.2rem;
      background-color: var(--gc-background-1);
      position: relative;
    }
    .ticket_dropzone_section {
      margin-bottom: 0;
      .custom_dropzone {
        p {
          text-align: center;
          font: 0.8rem 'Rubik', sans-serif;
        }
      }
      .dropdown_preview_list {
        .preview_item {
          .file_info {
            font: 0.7rem 'Rubik', sans-serif;
            justify-content: center;
          }
        }
      }
    }
  }
}
