.not_found_section {
  width: 100%;
  height: 100%;
  background: var(--theme-background-200);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--standard-margin);
  .not_found_container {
    width: fit-content;
    height: fit-content;
    text-align: center;
    padding: calc(var(--standard-padding) * 2);
    background-color: var(--theme-background-100);
    border-radius: 5px;
    span {
      font: 3rem 'Rubik', sans-serif;
      font-weight: 400;
    }
    p {
      margin: calc(var(--standard-margin) * 2) 0;
    }
    a {
      color: #ff0000;
    }
  }
}