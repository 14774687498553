.realtime_visible_typing {
  margin-top: var(--standard-margin);
  &:last-child {
    margin-bottom: unset;
  }
  .typing_message_box {
    max-height: 280px;
    overflow: auto;
    border-radius: var(--primary-radius);
    margin: calc(var(--standard-margin) / 2) calc(var(--standard-margin) / 2) var(--standard-margin) calc(var(--standard-margin) / 2);
    box-shadow: var(--card-shadow);
    padding: var(--standard-margin);
    background-color: #f7f4f4;
    .typing_message_box_header {
      border-bottom: 1px solid var(--theme-background-200);
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      font: 0.8rem 'Rubik', sans-serif;
      flex-wrap: wrap;
      word-break: break-word;
      .user_typing_info {
        display: flex;
        align-items: center;
        flex: auto;
        justify-content: flex-start;
      }
      .timestamp {
        display: flex;
        align-items: center;
        flex: 1 1;
        justify-content: flex-end;
        white-space: nowrap;
        font: 0.8rem 'Rubik', sans-serif;
        margin-right: var(--standard-margin);
      }
    }
    .typing_content {
      opacity: 0.5;
    }
  }
  .typing_note_header {
    border-bottom: 1px solid var(--theme-background-200);
    margin-bottom: 5px;
  }
}