.expander {
  background-color: transparent;
  .expander_header {
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: space-between;
    padding-top: var(--standard-padding);
    margin: 0;
    cursor: pointer;
    .expander_header_content {
      .expander_title{
        margin-bottom: 0;
        text-decoration: underline;
      }
    }
  }
  .expander_children {
    line-height: 20px;
    padding: var(--standard-padding) 0;
    margin: 0;
    overflow: hidden;
    .expander_body {
      padding: var(--standard-padding) 0;
      margin-left: calc(var(--standard-padding) * 5);
    }
  }
}
