.dropdown_section {
  position: relative;
  .dropdown_btn {
    cursor: pointer;
    border: 0;
    padding: 0;
    outline: none;
    position: relative;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    color: var(--dropdown-color-1);
    font: 0.8rem 'Rubik', sans-serif;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    svg {
      font: 1.5rem 'Rubik', sans-serif;
      border-radius: 50px;
      color: var(--color-1);
    }
  }
}
.dropdown_list {
  min-width: 120px;
  max-width: 500px;
  color: var(--dropdown-color-1);
  background-color: var(--dropdown-background-1);
  border-radius: 4px;
  z-index: 2010;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  max-height: 350px;
  padding: var(--standard-padding) 0;
  margin: 0;
  overflow: auto;
  li {
    padding: var(--standard-padding) calc(var(--standard-padding) * 2);
    width: auto;
    list-style: none;
    white-space: nowrap;
    font: 0.9rem 'Rubik', sans-serif;
    font-weight: 300;
    cursor: pointer;
    &:hover {
      background-color: var(--dropdown-hover-b-1);
    }
    svg{
      font: .8rem 'Rubik', sans-serif;
      margin-right: var(--standard-margin);
      top: 2px;
      position: relative;
    }
  }
}