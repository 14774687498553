.badge_item {
  position: relative;
  display: flex;
  justify-content: space-around;
  white-space: nowrap;
  align-items: center;
  font: 0.7rem 'Rubik', sans-serif;
  font-weight: 300;
  line-height: 1;
  padding: 0.3rem;
  position: relative;
  background-color: var(--tab-background-1);
  border: 2px solid transparent;
  color: var(--tab-color-1);
  cursor: pointer;
  &.active {
    background-color: var(--tab-active-color);
    border-color: var(--border-color-1) !important;
  }
  &.totalcount {
    background-color: var(--tab-background-200);
    max-width: 130px !important;
    .active_count {
      color: #000000;
    }
    .tab_menu_items {
      svg {
        color: #000000 !important;
      }
    }
    .badge_title {
      color: #000000;
    }
    .badge_btn  {
      color: #000000 !important;
    }
  }
  .badge_editable_text {
    padding: unset;
    background-color: transparent;
    font: inherit 'Rubik', sans-serif;
    outline: unset;
    border: 0;
    border-radius: unset;
    color: inherit;
  }
  .badge_wrapper {
    height: 15px;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .active_count {
      border-radius: 25px;
      color: var(--font-color-100);
      background-color: var(--theme-background-300);
      min-height: 20px;
      min-width: 20px;
      max-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tab_menu_items {
      .dropdown_btn {
        svg {
          font: 1rem 'Rubik', sans-serif;
          color: var(--tab-color-1);
        }
      }
    }
    .badge_btn {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      outline: 0;
      display: flex;
      font: 0.8rem 'Rubik', sans-serif;
      font-weight: 300;
      padding: 0;
      &.left_icon {
        margin: 0 0.3rem;
        color: var(--tab-color-1);
      }
      &.badge_title {
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--tab-color-1);
      }
      &.right_icon {
        margin: 0;
        margin-top: -3px;
        color: var(--tab-color-1);
      }
    }
    .items_length {
      font-family: var(--font-family);
      color: var(--tab-color-1);
      display: flex;
      align-items: center;
      margin-left: 0.1rem;
      &:after{
        content: ')'
      }
      &:before{
        content: '('
      }
    }
    .close_icon {
      display: flex;
      align-items: center;
      margin-left: 0.2rem;
    }
  }
  mwc-linear-progress {
    --mdc-theme-primary: var(--theme-background-300);
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

#portal {
  .tab_menu_items_popover {
    margin-top: calc(var(--standard-margin) * 3);
  }
}