.ticket_table_mobile_section {
  height: 100%;
  width: 100%;
  background-color: var(--background-1);
  color: var(--color-1);
  .ticket_item {
    padding: calc(var(--standard-margin) / 2) calc(var(--standard-margin) / 2) calc(var(--standard-margin) / 2) 0;
    border-bottom: 2px solid var(--separator-color);
    position: relative;
    &:first-child{
      border-top: 2px solid var(--separator-color);
    }
    a {
      text-decoration: none;
      color: var(--dark-color);
      .ticket_profile_info {
        width: 100%;
        padding: 5px 5px 5px 10px;
        .status_color_line {
          position: absolute;
          left: 1px;
          border-radius: 10px;
          height: 100%;
          top: 0px;
          width: 5px;
          margin-right: 0.2rem;
        }
        .ticket_header {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          word-break: break-word;
          .ticket_title{
            font-weight: 400;
            flex: auto;
            justify-content: flex-start;
          }
          .ticket_date {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: flex-end;
            white-space: nowrap;
            font: 0.7rem 'Rubik', sans-serif;
            .agent_badge {
              height: 16px;
              font: 0.7rem 'Rubik', sans-serif;
            }
          }
        }
        .ticket_preview {
          font: 0.9rem 'Rubik', sans-serif;
        }
      }
    }
  }
}