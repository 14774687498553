.change_password_form {
  width: 100%;
  .password_input_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    span {
      font: 0.9rem 'Rubik', sans-serif;
      font-weight: 400;
    }
  }
  .change_password_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--standard-margin);
  }
}