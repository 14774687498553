.agent_badge {
  border-radius: 25px;
  height: 20px;
  font: 0.8rem 'Rubik', sans-serif;
  padding: 0 var(--standard-padding);
  margin: 0 var(--standard-margin);
  background-color: var(--badge-background-1);
  color: var(--badge-color-1);
  display: flex;
  align-items: center;
}

.moz-signature {
  width: 100%;
  white-space: pre-line;
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}