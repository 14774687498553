.custom_search_dialog {
  width: 600px;
  .search_dialog_container {
    padding: var(--standard-padding);
    width: 100%;
    .error_search {
      font: 0.7rem 'Rubik', sans-serif;
      color: #ff0000;
    }
    .status_agent_select {
      display: flex;
      margin-top: var(--standard-margin);
      .status_select {
        flex: 1;
        margin-right: var(--standard-margin);
        display: flex;
        flex-direction: column;
        span {
          font-weight: 500;
        }
      }
      .agent_select {
        flex: 1;
        display: flex;
        flex-direction: column;
        span {
          font-weight: 500;
        }
      }
    }
    .date_range_picker {
      width: 100%;
      margin: var(--standard-margin) 0;
      .daterange_picker_checkbox {
        cursor: pointer;
        font: 0.8rem 'Rubik', sans-serif;
        display: flex;
        align-items: center;
      }
      .rdrDateRangePickerWrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        color: var(--calendar-color-1);
        @media screen and (max-width: 580px) {
          flex-direction: column;
        }
        .rdrDefinedRangesWrapper {
          background-color: var(--calendar-background-1);
          flex: 1;
          .rdrStaticRanges {
            .rdrStaticRange {
              background-color: var(--calendar-background-1);
              color: var(--calendar-color-1);
              :hover {
                background-color: var(--calender-selected-date);
                color: var(--calendar-color-1)!important;
              }
              &.rdrStaticRangeSelected {
                color: var(--calender-selected-date)!important;
              }
            }
          }
        }
        .rdrCalendarWrapper {
          color: var(--calendar-color-1);
          .rdrDateDisplayWrapper {
            background-color: var(--calendar-background-1);
            color: var(--calendar-color-1);
          }
          .rdrMonthAndYearWrapper {
            background-color: var(--calendar-background-1);
            .rdrMonthAndYearPickers {
              span {
                select {
                  color: var(--calendar-color-1);
                }
              }
            }
          }
          .rdrMonths {
            background-color: var(--calendar-background-1);
            .rdrMonth {
              .rdrMonthName {
                color: var(--calendar-color-1);
              }
              .rdrWeekDays {
                span {
                  color: var(--calendar-color-1);
                }
              }
              .rdrDays {
                .rdrDay {
                  &.rdrDayToday {
                    .rdrStartEdge {
                      color: var(--calender-selected-date)!important;
                    }
                  }
                  &.rdrDayHovered {
                    color: var(--font-color-600);
                  }
                  &.rdrDayDisabled {
                    background-color: var(--calender-disable-background);
                    .rdrDayNumber {
                      >span {
                        color: var(--calendar-disable-color);
                      }
                    }
                  }
                  .rdrDayStartPreview {
                    color: var(--calender-selected-date)!important;
                  }
                  .rdrDayEndPreview {
                    color: var(--calender-selected-date)!important;
                  }
                  .rdrDayInPreview {
                    color: var(--calender-selected-date)!important;
                  }
                  .rdrStartEdge {
                    color: var(--calender-selected-date)!important;
                  }
                  .rdrEndEdge {
                    color: var(--calender-selected-date)!important;
                  }
                  .rdrInRange {
                    color: var(--calender-selected-date)!important;
                  }
                  .rdrDayNumber {
                    >span {
                      color: var(--calendar-color-1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .checkbox_items {
      margin-top: var(--standard-margin);
      display: flex;
      .unassigned_checkbox {
        margin-right: var(--standard-margin);
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
          font: 0.8rem 'Rubik', sans-serif;
        }
      }
      .invert_checkbox {
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
          font: 0.8rem 'Rubik', sans-serif;
        }
      }
    }
    .search_tab_name {
      display: flex;
      flex-direction: column;
      margin: 0.5rem 0;
    }
    .search_dialog_button {
      display: flex;
      justify-content: flex-end;
    }
  }
}