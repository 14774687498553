.hidden_feature_dialog {
  display: flex;
  flex-direction: column;
  width: 350px;
  label {
    cursor: pointer;
  }
  .remove_btn {
    color: var(--font-color-600);
    padding: 0.5rem;
    width: fit-content;
    cursor: pointer;
  }
}