.group_chat_footer {
  padding: var(--standard-padding);
  background-color: var(--background-1);
  .footer_wrapper {
    height: 100%;
    width: 100%;
    border: 1px solid #dcdbdb;
    border-radius: 25px;
    display: flex;
    align-items: center;
    font: 0.9rem 'Rubik', sans-serif;
    textarea {
      outline: 0;
      height: 35px;
      min-height: 35px!important;
      max-height: 150px!important;
      max-width: calc(100% - 75px);
      min-width: calc(100% - 75px);
      white-space: pre-wrap;
      width: 100%;
      border: 0;
      color: var(--color-1);
      background-color: var(--background-1);
      font: 0.9rem 'Rubik', sans-serif;
      font-weight: 300;
    }
    .send_icon {
      cursor: pointer;
      font: 1.3rem 'Rubik', sans-serif;
      color: #807f7f;
      margin: 0 var(--standard-margin) var(--standard-margin) var(--standard-margin);
      align-self: flex-end;
      color: var(--color-1);
    }
  }
}

.dropzone_wrapper {
  position: relative;
  .dropzone_close_icon {
    position: absolute;
    right: 5px;
    top: 0px;
    color: var(--theme-background-300);
    cursor: pointer;
  }
}