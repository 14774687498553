.todo_icon {
  margin-left: 0.3rem;
  .trigger {
    .dropdown_section  {
      .dropdown_btn {
        svg {
          font-size: 1.3rem;
        }
      }
    }
  }
}

#portal {
  .todo_list_popover {
    min-width: 200px;
    max-width: 450px;
    .todo_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .todo_info {
        flex: 0.9;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .todo_title {
          flex: 0.9;
          font-weight: 500;
          font-size: 0.9rem;
          white-space: pre-wrap;
          word-break: break-all;
        }
        .todo_subtitle {
          flex: 0.9;
          font-size: 0.7rem;
          white-space: pre-wrap;
          word-break: break-all;
        }
        .todo_date {
          font-size: 0.7rem;
          display: flex;
          flex-direction: column;
        }
      }
      .remove_todo {
        margin: 0;
        font-size: 1.2rem;
        top: 0;
        width: 20px;
      }
    }
    .add_new_todo {
      text-align: center;
    }
  }
}