.livestatus_info_section {
  width: 140px;
  position: absolute;
  bottom: 13px;
  left: 50%;
  transform: translate(-50%, 0%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid var(--separator-color);
  cursor: pointer;
  .livestatus_info_header {
    align-items: center;
    display: flex;
    color: var(--font-color-100);
    padding: calc(var(--standard-padding) / 2);
    background-color: var(--background-2);
    justify-content: space-between;
    font: 0.8rem 'Rubik', sans-serif;
    span{
      svg{
        position: relative;
        top: 2px;
        margin-right: 5px;
        font-weight: bold;
      }
    }
  }
}

.mobile {
  .livestatus_info_section {
    bottom: 0;
  }
}

#portal {
  .livestatus_info_content {
    width: 140px;
    background-color: var(--theme-background-1);
    overflow: hidden;
    padding: calc(var(--standard-padding) / 2);
    display: flex;
    flex-direction: column;
    color: var(--font-color-900);
    border-radius: 4px;
    z-index: 2010;
    border: 1px solid var(--separator-color);
    .status_item {
      display: flex;
      align-items: center;
      font: 0.8rem 'Rubik', sans-serif;
      color: var(--color-1);
      svg {
        margin-right: var(--standard-margin);
      }
    }
  }
}