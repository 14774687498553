:root {
  --font-family: 'Rubik', sans-serif;
  --device-type: desktop;
  --device-orientation: portrait;
  --card-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  --primary-radius: 5px;
  --standard-padding: 0.5rem;
  --standard-margin: 0.5rem;
  --separator-color: rgba(45, 41, 41, 0.16);
  --note-box-shadow: #777;
  --font-color-100: #FFFFFF;
  --font-color-200: #F6B450;
  --font-color-600: #ff0000;
  --font-color-900: #000000;
  --theme-background-100: #FFFFFF;
  --theme-background-200: #E8E6E6;
  --theme-background-300: #F6B450;
  --theme-background-400: #FFE38F;
  --theme-background-600: #ff0000;

  /* NoteBox Variable */
  --note-background-100: #FFFAA8;
  --note-color-1: #000000;
  /* end */

  /* MessageBox Variable */
  --message-box-background-100: #FFFFFF;
  --message-box-font-color-900: #000000;
  --message-box-our-reply: #E8E6E6;
  --new-reply-background:#FFE38F;
  --message-box-font-color-1: #000000;
  /* end */

  /****** Comman Variable *******/
  --background-1: #FFFFFF;
  --background-2: #F6B450;
  --background-3: #000000;
  --background-4: #FFFFFF;
  --border-color-1: #F6B450;
  --color-1: #000000;
  --color-2: #F6B450;

  --badge-background-1: #444444;
  --badge-color-1: #F6B450;

  /* tabs variable */
  --tab-font-color-100: #FFFFFF;
  --tab-font-color-200: #000000;
  --tab-background-100: #DFE5F4;
  --tab-background-200: #FFE38F;
  --tab-border-100: #F6B450;

  --tab-background-1: #DFE5F4;
  --tab-color-1: #000000;
  --tab-active-color: #FFFFFF;
  /* end */

  /* dropdown */
  --dropdown-background-1: #FFFFFF;
  --dropdown-hover-b-1: #e1e1e1;
  --dropdown-color-1: #000000;

  --dialog-outer-background-1: rgba(0, 0, 0, 0.5);
  --theme-background-1: #FFFFFF;

  /* calender */
  --calendar-background-1: #FFFFFF;
  --calendar-color-1: #000000;
  --calender-selected-date: #F6B450;
  --calender-disable-background: rgba(0, 0, 0, 0.5);
  --calendar-disable-color: #FFFFFF;
  /* end */

  /* GroupChat */
  --gc-background-1: #F9F9F9;
  --gc-chatbox-background-1: #dfe5f4;
  --gc-chatbox-background-2: #e6e5e5;
  /* end */
}

html[data-theme="light-blue"] {
  /* Comman Variable */
  --background-2: #1164A3;
  --background-4: #1164A3;
  --border-color-1: #b8cbda;
  --color-2: #1164A3;

  --badge-background-1: #1164A3;
  --badge-color-1: #FFFFFF;

  /* dropdown */
  --dropdown-background-1: #1164A3;
  --dropdown-hover-b-1: #19171D;
  --dropdown-color-1: #FFFFFF;

  --dialog-outer-background-1: rgba(25, 23, 29, 0.5);
  --separator-color: #b8cbda;

  /* calender */
  --calender-selected-date: #1164A3;
  /* end */
}

html[data-theme="light-green"] {
  /* Comman Variable */
  --background-2: #33A532;
  --background-4: #33A532;
  --border-color-1: #33A532;
  --color-2: #33A532;

  --badge-background-1: #33A532;
  --badge-color-1: #FFFFFF;

  /* dropdown */
  --dropdown-background-1: #33A532;
  --dropdown-hover-b-1: #19171D;
  --dropdown-color-1: #FFFFFF;

  --dialog-outer-background-1: rgba(25, 23, 29, 0.5);
  --separator-color: #33A532;

  /* calender */
  --calender-selected-date: #33A532;
  /* end */
}

html[data-theme="dark-blue"] {
  /* Comman Variable */
  --background-1: #000;
  --background-2: #1164A3;
  --background-3: #FFFFFF;
  --background-4: #1164A3;
  --border-color-1: #254256;
  --color-1: #FFFFFF;
  --color-2: #1164A3;
  --text-area-bg-color:#020202;

  --badge-background-1: #1164A3;
  --badge-color-1: #FFFFFF;

  /* tabs variable */
  --tab-background-1: #121212;
  --tab-color-1: #FFFFFF;
  --tab-active-color: #1164A3;

  /* dropdown */
  --dropdown-background-1: #121212;
  --dropdown-hover-b-1: #1164A3;
  --dropdown-color-1: #FFFFFF;

  --dialog-outer-background-1: rgba(0, 0, 0, 0.8);
  --theme-background-1: #1f2021;
  --separator-color: #254256;

/* calender */
  --calendar-background-1: #121212;
  --calendar-color-1: #FFFFFF;
  --calender-selected-date: #1164A3;
  --calender-disable-background: rgba(255, 255, 255, 0.5);
  /* end */

  /* MessageBox Variable */
  --message-box-background-100: #1F2023;
  --message-box-font-color-900: #FFFFFF;
  --message-box-our-reply: #313131;
  --new-reply-background:#F6B450;
  /* end */

  /* GroupChat */
  --gc-background-1: #3a3b3c;
  --gc-chatbox-background-1: #20262b;
  --gc-chatbox-background-2: #313131;
  /* end */

  --note-background-100: #20262b;
  --note-color-1: #F9F9F9;
}

html[data-theme="dark-green"] {
  /* Comman Variable */
  --background-1: #121212;
  --background-2: #016300;
  --background-3: #FFFFFF;
  --background-4: #016300;
  --border-color-1: #3b5a3b;
  --color-1: #FFFFFF;
  --color-2: #016300;

  --badge-background-1: #016300;
  --badge-color-1: #FFFFFF;

  /* tabs variable */
  --tab-background-1: #121212;
  --tab-color-1: #FFFFFF;
  --tab-active-color: #016300;

  /* dropdown */
  --dropdown-background-1: #121212;
  --dropdown-hover-b-1: #016300;
  --dropdown-color-1: #FFFFFF;

  --dialog-outer-background-1: rgba(0, 0, 0, 0.8);
  --theme-background-1: #3a3b3c;
  --separator-color: #3b5a3b;

  /* calender */
  --calendar-background-1: #121212;
  --calendar-color-1: #FFFFFF;
  --calender-selected-date: #016300;
  --calender-disable-background: rgba(255, 255, 255, 0.5);
  /* end */

  /* MessageBox Variable */
  --message-box-background-100: #1F2023;
  --message-box-font-color-900: #FFFFFF;
  --message-box-our-reply: #313131;
  --new-reply-background:#F6B450;
  /* end */

  /* GroupChat */
  --gc-background-1: #3a3b3c;
  --gc-chatbox-background-1: #20262b;
  --gc-chatbox-background-2: #313131;
  /* end */

  --note-background-100: #20262b;
  --note-color-1: #F9F9F9;
}

html[data-theme="dark-orange"] {
  /* Comman Variable */
  --background-1: #121212;
  --background-2: #bd760a;
  --background-3: #FFFFFF;
  --background-4: #bd760a;
  --border-color-1: #5d4c34;
  --color-1: #FFFFFF;
  --color-2: #bd760a;

  --badge-background-1: #bd760a;
  --badge-color-1: #FFFFFF;

  /* tabs variable */
  --tab-background-1: #121212;
  --tab-color-1: #FFFFFF;
  --tab-active-color: #bd760a;

  /* dropdown */
  --dropdown-background-1: #121212;
  --dropdown-hover-b-1: #bd760a;
  --dropdown-color-1: #FFFFFF;

  --dialog-outer-background-1: rgba(0, 0, 0, 0.8);
  --theme-background-1: #3a3b3c;
  --separator-color: #5d4c34;

  /* calender */
  --calendar-background-1: #121212;
  --calendar-color-1: #FFFFFF;
  --calender-selected-date: #bd760a;
  --calender-disable-background: rgba(255, 255, 255, 0.5);
  /* end */

  /* MessageBox Variable */
  --message-box-background-100: #1F2023;
  --message-box-font-color-900: #FFFFFF;
  --message-box-our-reply: #313131;
  --new-reply-background:#bd760a;
  /* end */

  /* GroupChat */
  --gc-background-1: #3a3b3c;
  --gc-chatbox-background-1: #20262b;
  --gc-chatbox-background-2: #313131;
  /* end */

  --note-background-100: #20262b;
  --note-color-1: #F9F9F9;
}
