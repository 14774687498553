.message_box_section {
  background-color: var(--message-box-background-100)!important;
  color: var(--message-box-font-color-900)!important;
  &.isOurReply {
    background-color: var(--message-box-our-reply)!important;
    * {
      background-color: var(--message-box-our-reply)!important;
    }
    border-left: 2px solid var(--separator-color);
  }
  &.new_messagges {
    background-color: var(--new-reply-background)!important;
    * {
      background-color: var(--new-reply-background)!important;
    }
  }
  *{
    background-color: var(--message-box-background-100)!important;
    color: var(--message-box-font-color-900)!important;
  }
  .message_box_container {
    display: flex;
    flex-direction: column;
    padding: var(--standard-margin);
    .message_box_header {
      border-bottom: 1px solid var(--separator-color);
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      word-break: break-word;
      .profile_section {
        display: flex;
        align-items: center;
        flex: auto;
        justify-content: flex-start;
        flex-wrap: wrap;
        .more_link {
          .dropdown_btn {
            .title {
              font-weight: normal;
              font: 0.7rem 'Rubik', sans-serif;
            }
          }
        }
        .user_img {
          height: 50px;
          width: 50px;
          margin-right: calc(var(--standard-margin) * 2);
          img {
            height: 100%;
            width: 100%;
          }
        }
        .user_name {
          font-weight: 500;
          font: 1rem 'Rubik', sans-serif;
        }
        span {
          font: 0.8rem 'Rubik', sans-serif;
          margin-right: var(--standard-margin);
        }
        .message_status{
          font: 0.8rem 'Rubik', sans-serif;
          margin-left: var(--standard-margin);
        }
      }
      .datecreated {
        display: flex;
        align-items: center;
        flex: 1 1;
        justify-content: flex-end;
        white-space: nowrap;
        font: 0.8rem 'Rubik', sans-serif;
        margin-right: var(--standard-margin);
      }
      .messgae_box_more_options {
        .dropdown_btn {
          svg {
            color: var(--color-1);
          }
        }
      }
    }
    .message_box_content {
      .message_content {
        width: 100%;
        word-break: break-word;
        position: relative;
        text-indent: inherit !important;
        img {
          max-width: 100%;
          height: auto!important;
        }
      }
    }
    .attachment_section {
      border-top: 1px solid #c4c4c4;
      padding: calc(var(--standard-padding)*2) 0;
      margin-top: calc(var(--standard-margin)*2);
    }
    .read_more_less_content.more::before {
        content: '';
        height: 50px;
        display: block;
        position: absolute;
        width: 100%;
        background: inherit;
        margin-top: -50px;
        left: 0px;
        opacity: 0.5;
        box-shadow: 0px -11px 26px 10px #eaeaea;
    }
    .read_more_less_content {
      cursor: pointer;
      color: var(--message-box-font-color-900);
      padding: 0.5rem;
      font: 0.9rem 'Rubik', sans-serif;
      border-radius: 5px;
      font-weight: 400;
      padding: var(--standard-padding);
      background-color: var(--theme-background-200);
      display: flex;
      align-items: center;
      width: fit-content;
      svg {
        margin-right: calc(var(--standard-margin) / 2);
      }
    }
  }
}

#portal {
  .more_options_list {
    .split_ticket {
      svg {
        transform: rotate(90deg);
      }
    }
  }
  .more_link_popover {
    padding: var(--standard-padding);
  }
}