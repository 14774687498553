.transfer_selection {
  display: flex;
  align-items: center;
  span {
    font: 0.9rem 'Rubik', sans-serif;
    font-weight: 300;
  }
  .user_list {
    color: #000000;
    margin-left: var(--standard-margin);
    width: 250px;
  }
}

.note_editor {
  width: 100%;
  height: 100%;
  background-color: var(--note-background-100);
  border: 0;
  outline: unset;
}

.note_header_info {
  display: flex;
  padding-bottom: var(--standard-padding);
  color: var(--color-1);
  .postpone_note_section {
    display: flex;
    align-items: center;
    overflow: auto;
    flex: auto;
    .datepicker_section {
      display: flex;
      margin-right: var(--standard-margin);
      align-items: center;
      span {
        font: 0.9rem 'Rubik', sans-serif;
        font-weight: 300;
        margin-right: var(--standard-margin);
        white-space: nowrap;
        padding-left: 0.5rem;
      }
      .postpone_datepicker {
        font: 13px 'Rubik', sans-serif;
        border-radius: 4px;
        box-shadow: inset 0 2px 2px #e9e9e9;
        border: 1px solid #aeaeae;
        line-height: 16px;
        padding: var(--standard-margin);
      }
    }
    .pospone_btn_list {
      display: flex;
      flex: 1;
  
      .quick_postpone_btn {
        min-width: 0;
        margin-right: var(--standard-margin);
        white-space: nowrap;
        background: transparent;
        color: var(--color-1);
        border: 1px solid #ddd;
        padding: calc(var(--standard-padding) / 2) var(--standard-padding);
        height: 28px;
        box-shadow: none;
        font: 0.7rem 'Rubik', sans-serif;
        text-transform: none;
        &:hover {
          color: var(--font-color-200);
          box-shadow: var(--card-shadow);
        }
      }
    }
  }
  .reopen_radio_items {
    display: flex;
    align-items: center;
    margin-left: var(--standard-margin);
    flex: 1;
    white-space: nowrap;
    .radio_item {
      cursor: pointer;
      display: flex;
      align-items: center;
      input {
        margin-top: 0;
      }
    }
    span {
      margin-right: var(--standard-margin);
      font: 0.8rem 'Rubik', sans-serif;
    }
  }
}

.mobile {
  .transfer_selection {
    margin: 0 calc(var(--standard-margin) / 2);
    .user_list {
      flex: 1;
    }
  }
  .note_editor {
    font: 0.8rem 'Rubik', sans-serif;
    font-weight: 300;
  }
  .note_header_info {
    flex-direction: column;
    padding-bottom: 0;
    .reopen_radio_items {
      margin: 0 calc(var(--standard-margin) / 2);
    }
    .postpone_note_section {
      margin: 0 calc(var(--standard-margin) / 2);
      .datepicker_section {
        .postpone_datepicker {
          padding: calc(var(--standard-margin) / 2);
        }
      }
      .pospone_btn_list {
        .quick_postpone_btn {
          padding: calc(var(--standard-padding) / 2);
        }
      }
    }
  }
}