.world_clock_section {
  display: flex;
  font: 0.9rem 'Rubik', sans-serif;
  font-weight: 300;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  white-space: nowrap;
  @media screen and (min-width: 900px) and (max-width: 1129px) {
    font: 0.6rem 'Rubik', sans-serif;
    font-weight: 300;
  }
  .word_clock_item {
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    white-space: nowrap;
    padding: 0 calc(var(--standard-padding) / 2.5);
    font-weight: 300;
    border-top: 2px solid var(--border-color-1);
    span{
      font-weight: bold;
    }
  }
}