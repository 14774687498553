*, ::after, ::before {
  box-sizing: border-box;
}

body {
  margin: 0!important;
  font: 16px 'Rubik', sans-serif!important;
  font-weight: 300!important;
  line-height: 1.5!important;
  font-family: var(--font-family)!important;
  color: #000000!important;
}

div {
  display: block;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font: 2.5rem 'Rubik', sans-serif;
}

h2 {
  font: 2rem 'Rubik', sans-serif;
}

h3 {
  font: 1.75rem 'Rubik', sans-serif;
}

h4 {
  font: 1.5rem 'Rubik', sans-serif;
}

h5 {
  font: 1.25rem 'Rubik', sans-serif;
}

h6 {
  font: 1rem 'Rubik', sans-serif;
}

.cursor_pointer {
  cursor: pointer;
}

.full_height {
  height: 100% !important;
}

.full_width {
  width: 100% !important;
}

.site_height {
  height: calc(100% - 50px);
}

.react-responsive-modal-container.react-responsive-modal-containerCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}


.block-ui.block-ui-background:not(.text_editor .block-ui.block-ui-background) {
  display: flex;
  justify-content: center;
  align-items: center;
}


// html[data-theme="dark-orange"] .block-ui.full_height.full_width.block-ui-background {
//   background-color: #363738;
// }
html[data-theme^="dark-"] .MuiDialog-container > .MuiPaper-root{
  background-color: black;
  color: aliceblue;
}
html[data-theme^="dark-"] .block-ui.full_height.full_width.block-ui-background {
  background-color: #363738;
}

.react-select__menu-list{
  position: fixed !important;
  background-color: aliceblue;
  width: 264px;
}

.spinning >svg{
  height: 40px;
  width: 40px;
}

div.react-select__menu {
  position: absolute !important;
  z-index: 1000000000000000000 !important;
}

.block-ui-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.MuiDialogContent-root label {
  display: block;
}

.App {
  height: 100vh;
  position: relative;
}

.container {
  width: 100%;
  padding: 0 calc(var(--standard-padding) * 2);
  margin: 0 auto;
  @media screen and (min-width: 576px) {
    --container-width: 540px;
    max-width: var(--container-width);
  }
  @media screen and (min-width: 768px) {
    --container-width: 720px;
    max-width: var(--container-width);
  }
  @media screen and (min-width: 992px) {
    --container-width: 960px;
    max-width: var(--container-width);
  }
  @media screen and (min-width: 1200px) {
    --container-width: 1140px;
    max-width: var(--container-width);
  }
}

.device_detecor {
  // --device-type:desktop;
  --device-type:mobile;
  --device-orientation: portait;
  @media screen and (min-width: 576px) {
    // --device-type:desktop;
    --device-type:mobile;
  }
  @media screen and (min-width: 768px) {
    // --device-type:desktop;
    --device-type:mobile;
  }
  @media screen and (min-width: 992px) {
    --device-type:desktop;
  }
  @media screen and (min-width: 1200px) {
    --device-type:desktop;
  }
}

.ticket-code {
  cursor: pointer;
  border-bottom: 1px dashed var(--separator-color);
  text-decoration: none;
  color: inherit;
  font-style: italic;
}

.text_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.d_flex {
  display: flex;
}

.justify_start {
  justify-content: flex-start;
}

.justify_end {
  justify-content: flex-end;
}

.ml_auto {
  margin-left: auto;
}

.text_underline {
  text-decoration: underline;
}
.rel_icon_text{
  position: relative;
  top: 2px;
  margin-right: 3px;
  margin-left: -2px;
}

.text_bolder {
  font-weight: 'Rubik', sans-serif;
  font-weight: 500;
}

.MsoListParagraph{
  text-indent: inherit !important;
}

.ml_1 {
  margin-left: 1rem;
}

.ml_2 {
  margin-left: 2rem !important;
}

.react-datepicker__tab-loop{
  position: fixed !important;
  width: 100%;
}