.search_input {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #ddd;
  input {
    outline: none;
    font: 0.8rem 'Rubik', sans-serif;
    font-weight: normal;
    height: 100%;
    background-color: var(--theme-background-1);
    color: var(--color-1);
    border: solid 1px transparent;
    border-right-color: #ccc;
    padding: calc(var(--standard-padding) / 2) var(--standard-padding);
    width: 170px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    &:focus {
      width: 280px;
      @media only screen and (max-width: 1280px) {
        width: 170px;
      }
    }
  }
  .divider{
    display: inline-block;
    width: 1px;
    height: 30px;
    border-left: 1px solid #ddd;
  }
  svg {
    font-size: 1rem!important;
  }
}
