.multi_theme_dialog {
  width: 550px;
  .multi_theme_section {
    justify-content: space-around;
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    .theme_item {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--standard-margin);
      .theme_label {
        display: flex;
        text-transform: capitalize;
        align-items: center;
        input {
          margin-top: 0;
        }
        label {
          cursor: pointer;
          font-size: 0.9rem;
        }
      }
      .theme_preview {
        width: 250px;
        height: 130px;
        text-align: center;
        box-shadow: var(--card-shadow);
        padding: var(--standard-padding);
        border-radius: var(--primary-radius);
        background-color: var(--theme-background-100);
        margin-bottom: var(--standard-margin);
        img {
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .theme_accent {
    display: flex;
    justify-content: space-around;
    .accent_label {
      display: flex;
      text-transform: capitalize;
      font: 0.9rem 'Rubik', sans-serif;
      align-items: center;
      input {
        margin-top: 0;
      }
      label {
        cursor: pointer;
      }
    }
  }
}