.participants_form {
  width: 100%;
  .participants_input_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    span {
      font: 0.9rem 'Rubik', sans-serif;
    }
  }
  .participants_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--standard-margin);
  }
}