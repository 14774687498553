.tickets_right_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
  background-color: var(--theme-background-100);
  // border-radius: var(--primary-radius);
  box-shadow: var(--card-shadow);
  color: var(--font-color-900);
  .tickets_right_container {
    height: 100%;
    .tickets-grid-table {
      height: 100%;
      width: 100%;
      .nodata_found {
        display: flex;
        justify-content: center;
        padding: calc(var(--standard-padding) * 20);
        font: 1.2rem 'Rubik', sans-serif;
        font-weight: bold;
      }
    }
  }
}



