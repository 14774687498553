.default_note_section {
  height: 100%;
  .default_note_box {
    // height: 93%;
    width: 100%;
    margin: 0 auto;
    background-color: var(--background-1);
    padding: var(--standard-padding);
    padding-left: 20px;
    border-top: 1px solid var(--border-color-1);
    // box-shadow: var(--card-shadow);
    // box-shadow: 0 0 0 0.15rem rgba(230, 230, 230, 0.5);
    justify-content: space-between;
    display: flex;
    color: var(--color-1);
    // border-radius: 10px;
    .default_note_left {
      display: flex;
      align-items: center;
      flex: 1;
    }
    .ticket_status {
      font-weight: 300;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      flex: 1;
      .primary_btn {
        margin-right: var(--standard-margin);
        display: flex;
        align-items: center;
        white-space: nowrap;
        svg{
          margin-right: var(--standard-margin);
        }
      }
    }
  }
}

.mobile {
  .default_note_section {
    display: flex;
    .default_note_box {
      padding: calc(var(--standard-padding) / 2);
      font: 0.8rem 'Rubik', sans-serif;
      margin: 0;
      flex: 1;
    }
  }
}