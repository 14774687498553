.attachment_section {
  display: flex;
  .attachment_img {
    width: 200px;
    text-align: center;
    box-shadow: var(--card-shadow);
    padding: var(--standard-padding);
    border-radius: var(--primary-radius);
    background-color: var(--theme-background-100);
    img {
      max-height: 100%;
      max-width: 100%;
      display: flex;
      border-radius: var(--primary-radius);
      cursor: pointer;
    }
  }
  .attachment_content {
    display: flex;
    flex-direction: column;
    margin-left: var(--standard-margin);
    width: calc(100% - 200px);
    color: var(--note-color-1);
    .attachment_file_info {
      display: flex;
      align-items: center;
      white-space: nowrap;
      p {
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        margin-right: var(--standard-margin);
      }
    }
    .file_size {
      font: 0.8rem 'Rubik', sans-serif;
      margin-left: var(--standard-margin);
      &:after{
        content: ')'
      }
      &:before{
        content: '('
      }
    }
    .attachment_btn {
      display: flex;
      align-items: center;
      :first-child {
        margin-right: var(--standard-margin);
      }
      p {
        cursor: pointer;
        color: var(--note-color-1);
        text-transform: capitalize;
      }
    }
  }
}

.mobile {
  .attachment_section {
    .attachment_content {
      .attachment_file_info {
        p {
          flex: 1;
        }
      }
    }
  }
}