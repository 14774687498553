/* Font Definitions */
@font-face {
  font-family:"Cambria Math";
  panose-1:2 4 5 3 5 4 6 3 2 4;
}
@font-face
{font-family:Calibri;
panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
{font-family:Consolas;
panose-1:2 11 6 9 2 2 4 3 2 4;}
/* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal
{margin:0in;
margin-bottom:.0001pt;
font-size:11.0pt;
font-family:"Calibri",sans-serif;}
pre
{mso-style-priority:99;
mso-style-link:"HTML Preformatted Char";
margin:0in;
margin-bottom:.0001pt;
font-size:10.0pt;
font-family:"Courier New";}
span.HTMLPreformattedChar
{mso-style-name:"HTML Preformatted Char";
mso-style-priority:99;
mso-style-link:"HTML Preformatted";
font-family:Consolas;}
.MsoChpDefault
{mso-style-type:export-only;
font-size:10.0pt;}
@page WordSection1
{size:8.5in 11.0in;
margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
{page:WordSection1;}

.block-ui-background {
  background-color: var(--theme-background-1);
}

.message_content {
  #signature_old {
    color: var(--message-box-font-color-900)!important;
  }
  .message_style_overload {
    color: var(--message-box-font-color-900)!important;
    background-color: transparent!important;
    overflow: auto;
    div {
      color: var(--message-box-font-color-900)!important;
    }
    span {
      background-color: transparent!important;
      color: var(--message-box-font-color-900)!important;
    }
    a {
      color: var(--background-2)!important;
    }
  }
}