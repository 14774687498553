.ticket_list_table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  background-color: var(--theme-background-100);
  .tr-header {
    display: none!important;
    // &:first-child {
    //   display: none!important;
    // }
  }
  // .tr-header {
  //   flex: 1 0 auto;
  //   display: flex;
  //   flex-direction: column;
  //   -webkit-user-select: none;
  //   -moz-user-select: none;
  //   -ms-user-select: none;
  //   user-select: none;
  //   box-shadow: 0 2px 15px 0 rgba(0,0,0,.15);
  //   padding-right: 14px;
  //   .rt-tr {
  //     flex: 1 0 auto;
  //     text-align: center;
  //     display: flex;
  //     background-color: var(--theme-background-100);
  //     border-bottom: 1px solid var(--separator-color);
  //     .rt-th {
  //       white-space: nowrap;
  //       text-overflow: ellipsis;
  //       padding: 5px;
  //       line-height: normal;
  //       position: relative;
  //       border-right: 1px solid var(--separator-color);
  //       box-shadow: inset 0 0 0 0 transparent;
  //       overflow: visible;
  //     }
  //   }
  // }
  .rt-tbody {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    color: var(--color-1);
    background-color: var(--background-1);
    .rt-tr {
      flex: 1 0 auto;
      border-bottom: 1px solid var(--border-color-1);
      align-items: center;
      .rt-td {
        // white-space: nowrap;
        // text-overflow: ellipsis;
        padding: 0 var(--standard-padding);
        overflow: hidden;
        border-right: 1px solid rgba(0,0,0,.02);
        &:first-child {
          padding-left: 0;
        }
        .agent_badge {
          margin-left: auto;
        }
      }
    }
  }
}