.internal_ticket_form {
  height: 100%;
  display: flex;
  flex-direction: column;
  .create_ticket_textarea {
    height: 100%;
    margin: var(--standard-margin) 0;
    textarea {
      height: 160px;
      border: 0;
      outline: 0;
      padding: 0 calc(var(--standard-padding) / 2);
      font: 0.8rem 'Rubik', sans-serif;
      font-weight: 300;
    }
  }
  .input_label {
    font: 0.8rem 'Rubik', sans-serif;
    cursor: pointer;
    margin: 0 calc(var(--standard-margin) / 2);
    font-weight: 500;
  }
  .ticket_input_wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 10px;
    .create_ticket_input {
      input {
        border: 0;
        border-bottom: 1px solid #ced4da;
        outline: 0;
        padding: calc(var(--standard-padding) / 2);
        font-size: 0.8rem;
      }
    }
  }
  .create_ticket_submit {
    width: fit-content;
    align-self: flex-end;
  }
}