.card_section {
  // display: flex;
  // flex-direction: column;
  margin-bottom: 0.25rem;
  .card_preview_section {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .profile_image {
      width: 30px;
      height: 30px;
      background-color: var(--gc-chatbox-background-2);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font: 0.8rem 'Rubik', sans-serif;
      text-transform: uppercase;
      color: var(--color-1);
    }
    .agent_name {
      font: 0.8rem 'Rubik', sans-serif;
      font-weight: 400;
      margin: 0 0.5rem;
      color: var(--color-1);
    }
  }
  .card_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .message_content {
      background-color: var(--gc-chatbox-background-1);
      padding: 0.5rem;
      border-radius: 10px 0px 10px 10px;
      width: fit-content;
      line-height: 1;
      word-break: break-word;
      font: 0.9rem 'Rubik', sans-serif;
      font-weight: 300;
      min-width: 80px;
      min-height: 25px;
      max-width: 100%;
      &.newMessage {
        background-color: var(--new-reply-background)!important;
      }
    }
    .reply_time {
      font: 10px 'Rubik', sans-serif;
      margin-top: 0.1rem;
      margin-right: 0.4rem;
      color: var(--color-1);
    }
  }
  &.agent_reply {
    .card_preview_section {
      flex-direction: row;
    }
    .card_content {
      margin-right: 0;
      margin-left: 2.5rem;
      display: flex;
      justify-content: flex-start;
      .message_content {
        border-radius: 0 10px 10px 10px;
        background-color: var(--gc-chatbox-background-2);
        align-self: end;
      }
      .reply_time {
        align-self: end;
      }
    }
  }
}
.datetime {
  font: 0.7rem 'Rubik', sans-serif;
  color: #868686;
  text-align: center;
  color: var(--color-1);
}