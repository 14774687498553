.notesection {
  // height: 100%;
  width: 100%;
  padding-bottom: var(--standard-padding);
  border-bottom: 1px solid var(--border-color-1);
  font: 1rem 'Rubik', sans-serif;
  font-weight: 300;
  margin: calc(var(--standard-margin) / 2) 0;
  // border-radius: 10px 0px 10px 0px;
  line-height: 22px;
  &:last-child{
    border: 0px;
  }
  &.new_messagges {
    background-color: var(--new-reply-background);
  }
  .noteheader {
    padding: var(--standard-padding);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    word-break: break-word;
    align-items: center;
    color: var(--color-1);
    .header_left {
      flex: auto;
      justify-content: flex-start;
      .user_name{
        margin-right: 4px;
      }
      .note_content {
        width: 100%;
        word-break: break-word;
      }
    }
    .header_right {
      display: flex;
      align-items: center;
      flex: 1 1;
      justify-content: flex-end;
      white-space: nowrap;
      font: 0.8rem 'Rubik', sans-serif;
    }
  }
  .note_section {
    // border-radius: 10px 0px 10px 0px;
    .note_content {
      color: var(--note-color-1);
      width: 100%;
      word-break: break-word;
    }
    .attachment_section {
      border-top: 1px solid var(--separator-color);
      padding: calc(var(--standard-padding)*2) 0;
      margin-top: calc(var(--standard-margin)*2);
    }
  }
}