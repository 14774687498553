.agents_ticket_icon {
  margin-left: 0.5rem;
}

#portal {
  .agents_ticket_popover {
    min-width: 200px;
    max-width: 450px;
    max-height: 450px;
    .specific_agents_tickets {
      display: flex;
      align-items: center;
      .specific_agents_label {
        display: flex;
        justify-content: space-between;
        flex: 1;
        .last_activity_time {
          font-size: 0.7rem;
          display: flex;
        }
        .agents_counts {
          margin-left: 5px;
        }
      }
      .active_status {
        width: 10px;
        height: 10px;
        background-color: green;
        border-radius: 50%;
        margin-right: 10px;
      }
      .idle_status {
        width: 10px;
        height: 10px;
        background-color: #bcbcbc;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
}