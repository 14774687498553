.attchment_clip {
  margin-right: calc(var(--standard-margin) * 2);
  color: var(--color-1);
  cursor: pointer;
}
.group_chat_icon {
  display: flex;
  svg {
    margin-right: calc(var(--standard-margin) * 2);
    color: var(--color-1);
    cursor: pointer;
  }
}