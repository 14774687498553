.signin_section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  .form_section {
    width: 400px;
    margin: 0 auto;
    padding: var(--standard-padding);
    background-color: var(--theme-background-100);
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    .logo {
      width: 170px;
      margin-bottom: var(--standard-margin);
    }
    .form_input {
      .input_wrapper {
        margin-bottom: calc(var(--standard-margin) * 2);
        input {
          height: 40px;
          font: 15px 'Rubik', sans-serif;
          font-weight: 300;
          line-height: 1.5;
          color: var(--font-color-900);
          display: block;
          width: 100%;
          background-color: #e2e1ea;
          padding: 0 calc(var(--standard-padding) * 2);
          border-radius: 0.5rem;
          border: 0;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .form_btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .primary_btn {
        width: 120px;
        box-shadow: unset;
        border-radius: 20px;
      }
    }
    .forgot_link {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
    }
    .remember_me {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .signup_link {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      
    }
  }
}

.mobile {
  .signin_section {
    background-color: var(--theme-background-100);
    .form_section {
      box-shadow: none;
      width: 100%;
      text-align: center;
      .form_btn {
        justify-content: center;
        .primary_btn {
          width: 100%;
        }
      }
    }
  }
}