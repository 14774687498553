.group_chat_header {
  min-height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: calc(var(--standard-padding) / 2) var(--standard-padding);
  background-color: var(--background-1);
  color: var(--color-1);
  .group_chat_search_input {
    height: 100%;
    width: 100%;
    border: 1px solid #ddd;
    outline: 0;
  }
  .right_section {
    display: flex;
    flex-direction: column;
    line-height: 1;
    width: calc(100% - 45px);
    word-break: break-all;
    .ticket_id {
      font: 0.8rem 'Rubik', sans-serif;
      font-weight: bold;
    }
    .ticket_viewer {
      font: 0.8rem 'Rubik', sans-serif;
    }
  }
  .header_icon {
    cursor: pointer;
    width: 15px;
  }
  .group_chat_newmsg {
    position: absolute;
    bottom: -30px;
    border-radius: 50px;
    display: flex;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #F6B450;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font: 0.8rem 'Rubik', sans-serif;
    z-index: 555;
    padding: 0.3rem 0.5rem;
    .down_svg {
      cursor: pointer;
      margin-right: 0.2rem;
    }
    .close_svg {
      cursor: pointer;
      margin-left: 0.2rem;
      font-weight: 500;
    }
  }
}