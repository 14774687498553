.activity_time_tracker {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: var(--standard-margin);
  &.pauseTracking {
    color: var(--font-color-600);
  }
  .activity_timer_section {
    display: flex;
    align-items: center;
    .local_time {
      font-weight: bold;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        margin-right: 5px;
      }
      .separator {
        margin: 0 5px;
      }
      .total_hours_info_btn {
        .dropdown_btn {
          font-weight: bold;
          display: flex;
          font: 1rem 'Rubik', sans-serif;
        }
      }
    }
  }
  .last_activity_time {
    font-size: 0.7rem;
  }
}

#portal {
  .total_hours_info {
    margin-top: var(--standard-margin);
    .hours_item {
      display: flex;
      .activity_date {
        width: 130px;
        margin-right: var(--standard-margin);
        span {
          font-weight: 400;
        }
      }
      .activity_time {
        margin-right: var(--standard-margin);
        span {
          font-weight: 400;
        }
      }
    }
  }
}