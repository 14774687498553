.search_move {
  display: flex;
  padding: 0.5rem;
  width: fit-content;
  background-color: #E6E5E5;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  position: absolute;
  font-size: 0.8rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.12),0 2px 4px 0 rgba(0,0,0,.08);
  bottom: -45px;
  z-index: 150;
  right: 25px;
  svg {
    margin: 0 2px;
    cursor: pointer;
    &.disable {
      color: #a2a0a0;
    }
  }
}