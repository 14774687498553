.input_wrapper {
  width: 100%;
  .input_control {
    width: 100%;
    display: block;
    padding: .40rem .60rem;
    font: 1rem 'Rubik', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    color: var(--color-1);
    background-color: var(--theme-background-1);
    border: 1px solid var(--separator-color);
    border-radius: .25rem;
    &.error_field {
      border-color: var(--theme-background-600);
    }
  }
  .error_text {
    color: var(--font-color-600);
    font: .75rem 'Rubik', sans-serif;
    margin: 0;
    padding: 0;
    float: left;
    line-height: 1rem;
  }
}