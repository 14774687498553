.simple_input_control {
  width: 100%;
  display: block;
  padding: .40rem .60rem;
  font: 1rem 'Rubik', sans-serif;
  font-weight: 300;
  line-height: 1.5;
  color: var(--color-1);
  background-color: var(--theme-background-1);
  border: 1px solid #ced4da;
  border-radius: .25rem;
}