.tickets_wrapper_section {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  .tickets_wrapper_row {
    height: 100%;
    // width: 100%;
    flex: 1;
    display: flex;
    padding: var(--standard-padding);
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    background-color: var(--theme-background-1);
    .ticket_wrapper_loader {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .ticket_list_mobile {
      width: 100%;
      padding: var(--standard-padding);
      margin-bottom: var(--standard-margin);
      background-color: var(--theme-background-100);
      border-radius: var(--primary-radius);
    }
  }
  .group_chatbox_wrapper {
    padding: 0.6rem;
    background-color: var(--theme-background-1);
    display: flex;
    align-items: center;
  }
}

.mobile {
  .tickets_wrapper_section {
    .tickets_wrapper_row {
      width: 100%;
      padding: calc(var(--standard-padding) / 2) 0;
      overflow: unset;
    }
  }
}